/* eslint-disable react/no-danger */
import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import RatingStars from './RatingStars';

const Review = ({
  review,
  schema = false
}) => {
  const reviewProps = {
    className: "review"
  };
  const authorProps = {
    className: "author"
  };
  const dateProps = {
    className: "publisherDate"
  };
  const ratingProps = {
    className: "rating"
  };
  const reviewBodyProps = {};
  const authorNameProps = {};

  if (schema) {
    reviewProps.itemProp = "review";
    reviewProps.itemScope = "itemscope";
    reviewProps.itemType = "https://schema.org/UserReview";

    reviewBodyProps.itemProp = "reviewBody";

    authorProps.itemProp = "author";
    authorProps.itemScope = "itemscope";
    authorProps.itemType = "https://schema.org/Person";

    authorNameProps.itemProp = "name";

    dateProps.itemProp = "datePublished";

    ratingProps.itemProp = "reviewRating";
    ratingProps.itemScope = "itemscope";
    ratingProps.itemType = "https://schema.org/Rating";
  }

  const ifNoRatingUseThis = 5;
  const rating = review.rating || ifNoRatingUseThis;

  return (
    <div {...reviewProps}>
      <div className="author-date">
        <div {...authorProps}><span {...authorNameProps}>{review.authorName || 'Anonymous'}</span></div>
        <div {...dateProps}>{new Date(review.publisherDate).toLocaleDateString('en-US', { dateStyle: 'long' })}</div>
      </div>
      <div className="content">
        <div className="ratingStars">
          <RatingStars rating={rating} size={16} iconColor="#ffd000" starType="lmds" />
        </div>
        <div {...ratingProps}>
          {`${rating} / 5 stars`}
          {schema && <meta itemProp="ratingValue" content={rating} />}
        </div>
        {!!review.content && <div {...reviewBodyProps}>{review.content}</div>}
        {review.comments?.length > 0 &&
          <div className="comments">
            <div className="title">Response from Comparion Insurance</div>
            {review.comments.map(comment => (
              <Fragment key={comment.content}>
                <div className="commentDate">{new Date(comment.publisherDate).toLocaleDateString('en-US', { dateStyle: 'long' })}</div>
                <div className="comment">{comment.content}</div>
              </Fragment>
            ))}
          </div>}
      </div>
      <style jsx>{`
        .review{
          padding: 1.5rem;

          &:not(:last-child) {
            border-bottom: 1px solid #e6e6e6;
          }
          @media screen and (min-width: 75rem) {
            flex-direction: row;
          }
        }
        .author-date {
          width: 100%;
          margin-bottom: 1rem;
          @media screen and (min-width: 75rem) {
            max-width: 25%;
            margin-bottom: 0;
          }
        }
        .author {
          font-weight: 700;
        }
        .content {
          width: 100%;

          @media screen and (min-width: 75rem) {
            max-width: 75%;
          }
          .title {
            font-weight: 700;
            margin-top: 2rem;
          }
          .commentDate {
            margin-bottom: 1rem;
          }
        }
        .rating{
          margin-bottom: 1rem;
        }
        .ratingStars{
          margin-top: 1.5rem;
          height: 1.2rem;
        }
      `}</style>
    </div >
  );
};

Review.propTypes = {
  review: PropTypes.shape({
    rating: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    publisherDate: PropTypes.number.isRequired,
    comments: PropTypes.arrayOf(PropTypes.shape({
      publisherDate: PropTypes.number,
      authorName: PropTypes.string,
      authorRole: PropTypes.string,
      authorEmail: PropTypes.string,
      content: PropTypes.string,
      visibility: PropTypes.string
    })),
  }).isRequired,
  schema: PropTypes.bool
};

export default Review;
